import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/sistema/plantillas`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})
  
// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  /**
   * Actualiza una plantilla del sistema
   * @param {string} nombrePlantilla 
   * @param {text} contenido 
   * @returns Promise
   */
  actualizar(nombrePlantilla, contenido) {
    contenido = contenido || ''
    return apiClient.post(`${nombrePlantilla}`, {contenido: contenido});
  },
  
  actualizarPlantillas(plantillas) {
    return apiClient.post('', {plantillas: plantillas})
  },

  plantillaPorDefectoJSON(nombrePlantilla) {
    return apiClient.get(`por-defecto/${nombrePlantilla}.json`)
  },

  plantillasPorDefectoJSON(nombresPlantillas) {
    return apiClient.get(`por-defecto.json`, {params: {nombres_plantillas: nombresPlantillas}})
  },

  plantillaJSON(nombrePlantilla) {
    return apiClient.get(`${nombrePlantilla}.json`)
  },

  plantillasJSON(nombresPlantillas) {
    nombresPlantillas = nombresPlantillas || []
    return apiClient.get(`${API}/sistema/plantillas.json`, {params: {nombres_plantillas: nombresPlantillas}})
  },
}




